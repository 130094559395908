<template>
  <div>
    <div class="block banner2 pc">
    </div>
    <div class="block">
      <div class="container">
        <div class="company-info">
          <div class="img">
            <img src="@/assets/images/pic4.jpg"/>
          </div>
          <div class="text">
            <div class="title">{{$t('companyInfo')}}</div>
          <p>{{$t('about1')}}</p>
          <p>{{$t('about2')}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('about3')}}</h2>
        <ul class="worth-list">
          <li class="ico13">
            <div class="tit">{{$t('about4')}}</div>
            <p clas="des">{{$t('about5')}}</p>  
          </li>
          <li class="ico9">
            <div class="tit">{{$t('about6')}}</div>
            <p clas="des">{{$t('about7')}}</p>
          </li>
          <li class="ico11">
            <div class="tit">{{$t('about8')}}</div>
            <p clas="des">{{$t('about9')}}</p> 
          </li>
          <li class="ico8">
            <div class="tit">{{$t('about10')}}</div>
            <p clas="des">{{$t('about11')}}</p>
          </li>
          <li class="ico10">
            <div class="tit">{{$t('about12')}}</div>
            <p clas="des">{{$t('about13')}}</p> 
          </li>
          <li class="ico12">
            <div class="tit">{{$t('about14')}}</div>
            <p clas="des">{{$t('about15')}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="block blue">
      <div class="cover-bg2"></div>
      <div class="container">
        <div class="title">{{$t('about16')}}</div>
        <ul class="milestone-box">
          <li>
            <div class="des">
              <div class="year">{{$t('about17')}}</div>
              <p>{{$t('about18')}}</p>
              <img src="@/assets/images/pic1.jpg"/>
            </div>
          </li>
          <li>
            <div class="des">
              <div class="year">{{$t('about19')}}</div>
            <p>{{$t('about20')}}</p>
          </div>
          </li>
          <li>
            <div class="des">
              <div class="year">{{$t('about21')}}</div>
            <p>{{$t('about22')}}</p>
            </div>
          </li>
          <li>
            <div class="des">
              <div class="year">{{$t('about23')}}</div>
            <p>{{$t('about24')}}</p>
            <img src="@/assets/images/pic2.jpg"/>
            </div>
          </li>
          <li>
            <div class="des">
              <div class="year">{{$t('about25')}}</div>
              <p>{{$t('about26')}}</p>
              <img src="@/assets/images/pic3.jpg"/>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block address">
      <div class="container">
        <el-row>
          <el-col :md="12" :sm="24">
            <h3>ADDRESS</h3>
            <p class="gray">{{$t('about27')}}<br/>
              {{$t('about28')}}<br/>
              {{$t('about29')}}</p>
              <p class="gray">
                {{$t('about30')}}<br/>
                {{$t('about31')}}<br/>  
                {{$t('about32')}}
              </p>
              <p>
                {{$t('about33')}}<br/>
              +85294374585
              </p>
              <p>
                {{$t('about34')}}<br/>
              @fulling123
              </p>
          </el-col>
          <el-col :md="12" :sm="24"><img src="@/assets/images/address.png"/></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUs",
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('aboutUs')+'-'+this.$t('home80'),
    }
  }
}
</script>
<style lang="scss">
  .company-info {
    display: flex;
    flex-direction: row-reverse;
    color: #232427;
    align-items: center;
    .text {
      flex: 1;
      .title {
      font-size: 0.36rem;
      font-weight: 700;
    }
    p {
      font-size: 0.18rem;
    }
    }
    .img {
      flex: 1;
      img {
        width: 95%;
        margin-left: 5%;
      }
    }
  }
  .worth-list {
    overflow: hidden;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    li {
      width: 33.33%;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: auto 0.36rem;
      padding-top: 0.5rem;
      margin-bottom: 0.9rem;
      &.ico13 {
        background-image: url('~@/assets/images/ico13.svg');
      }
      &.ico9 {
        background-image: url('~@/assets/images/ico9.svg');
      }
      &.ico8 {
        background-image: url('~@/assets/images/ico8.svg');
      }
      &.ico10 {
        background-image: url('~@/assets/images/ico10.svg');
      }
      &.ico11 {
        background-image: url('~@/assets/images/ico11.svg');
      }
      &.ico12 {
        background-image: url('~@/assets/images/ico12.svg');
      }
      .tit {
      font-size: 0.2rem;
      color: #232427;
      font-weight: bold;
      margin-bottom: 0.05rem;
    }
    p {
      color: #939497;
      font-size: 0.14rem;
      margin: 0;
    }
    }
  }

  .milestone-box {
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 4rem 0;
    &:before {
      content: '';
      height: 0.01rem;
      position: absolute;
      background-color: rgba(255,255,255,0.57);
      left: 0;
      width: 100%;
      top: 0.1rem;
    }
    li {
      width: 0.2rem;
      height: 0.2rem;
      border-radius: 1rem;
      background-color: #FF9900;
      border: 0.04rem solid #ffffff;
      position: relative;
      .des {
        position: absolute;
        width: 2.4rem;
        left: -0.1rem;
        top: 0.45rem;
        .year {
          font-size: 0.32rem;
          font-weight: 700;
        }
        img {
          width: 90%;
        }
      }
      &:nth-of-type(2), &:nth-of-type(4) {
        .des {
          top: auto;
          bottom: 0.45rem;
        }
      }
    }
  }
  .address {
    .gray {
      color: #939497;
    }
  }
  @media (max-width: 1179px) {
    
    .company-info {
      flex-direction: column !important;
      .text {
        .title {
          font-size: 0.28rem !important;
        }
      }
    }
    .company-info .text p {
      font-size: 0.14rem !important;
    }
    .company-info .img img {
      width: 100% !important;
      margin: 0 0 0.1rem 0 !important;
    }
    .worth-list li {
      width: 50% !important;
    }
    .milestone-box {
      flex-direction: column;
      margin: 0.5rem 0 !important;
    }
    .milestone-box li {
      margin: 1rem 0;
      width: 0.1rem;
      height: 0.1rem;
      border: 0.02rem solid #ffffff;
      &:nth-of-type(1) {
        margin-top: 0;
      }
      &:nth-of-type(5) {
        margin-bottom: 2.5rem;
      }
    }
    .milestone-box:before {
      left: 0.05rem !important;
      width: 1px !important;
      height: 100% !important;
      top: 0 !important;
    }
    .milestone-box li .des {
      width: 2.5rem !important;
      left: 0.2rem !important;
      top: -0.1rem !important;
    }
    .milestone-box li .des img {
      width: 50% !important;
    }
    .milestone-box li .des .year {
      font-size: 0.2rem;
    }
    .worth-list li .tit {
      font-size: 0.18rem;
    }
    .worth-list li {
      background-size: 0.28rem;
      padding-top: 0.4rem;
      margin-bottom: 0.5rem;
    }
    .worth-list li p {
      font-size: 0.12rem;
    }
  }
</style>
